<template>
  <div class="haishiSub">
    <img src="./image/yuyue_bck.png" alt="" />
    <div class="yuyue_box">
      <div class="title">请填写客户预约信息</div>
      <div class="yuyue">
        <div class="yuyue_t1">
          <span>{{ hsname }}</span>
          <p>{{ form.fwlx }}</p>
        </div>
        <div class="yuyue_t2">
          <el-form ref="ruleForm" :model="form" :rules="rules">
            <div class="zzsx">
              <el-radio-group
                v-model="form.fwlxr"
                @change="fwlxChange(form.fwlxr, 'fwlxr')"
              >
                <el-radio-button
                  v-for="(item, key) in hsfw"
                  :key="key"
                  :label="item.title"
                ></el-radio-button>
              </el-radio-group>
              <el-select
                v-for="(item, key) in hsfw_arr"
                :key="key"
                v-model="form['fwlx' + key]"
                width="100%"
                :placeholder="item.title"
                @change="fwlxChange(form['fwlx' + key], 'fwlx' + key)"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="(items, keys) in item.list"
                  :key="keys"
                  :label="items"
                  :value="items"
                >
                </el-option>
              </el-select>
            </div>

            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="手机号"
                :maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-autocomplete
                v-model="form.htbh"
                :fetch-suggestions="querySearch"
                placeholder="合同编号(非必填)"
                @select="handleSelect"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ item.name }}</div>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item prop="name">
              <el-input v-model="form.name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item prop="gtcs">
              <el-input
                type="number"
                v-model.number="form.gtcs"
                placeholder="请输入本次服务需要沟通的次数"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-col :span="11">
                <el-form-item prop="kssj">
                  <el-date-picker
                    type="date"
                    :picker-options="startOptions"
                    placeholder="开始日期"
                    v-model="form.kssj"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="jssj">
                  <el-date-picker
                    type="date"
                    placeholder="结束日期"
                    v-model="form.jssj"
                    :picker-options="startOptions"
                    style="width: 100%;"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            <div class="syfucs" v-show="syfwcs != '' && form.htbh != ''">
              服务剩余{{ syfwcs }}次
            </div>
            <el-form-item>
              <el-button
                class="tijiao"
                type="primary"
                @click="onSubmit('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
      title="恭喜您预约提交成功"
      :visible.sync="dialogVisible"
      center
      :before-close="queren"
    >
      <span>
        预约海师{{
          hsname
        }}已成功提交，运营老师会在18小时内与您进行沟通，请耐心等待！
      </span>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="queren"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getServices, getContract, addOrder } from "@/api/haishi";
export default {
  data() {
    return {
      dialogVisible: false, //预约成功弹窗
      hsname: "", //海师名称
      hsfw: [], //服务单选
      hsfw_arr: [], //服务下拉框数据
      hetong: [], //合同
      xsid: "", //学生ID
      syfwcs: "", //剩余服务次数
      startOptions:{
          disabledDate(time) {
            return time.getTime() < (Date.now()-86400000);
          },
        },//开始时间
      form: {
        fwlx: "", //服务类型
        fwlxr: "",
        fwlx0: "",
        fwlx1: "",
        fulx2: "",
        gtcs: 1, //沟通次数
        name: "", //姓名
        phone: "", //手机号
        htbh: "", //合同编号
        kssj: "", //开始时间
        jssj: "", //结束时间
      },
      fwlxItem: {
        type1: [],
        type2: [],
      },
      haishi: {
        name: "",
        fuwu: [],
        num: 0,
      },
      //表单校验
      rules: {
        gtcs: [
          { required: true, message: "请输入服务次数", trigger: "blur" },
          { type: "number", message: "请输入数字", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { len: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        kssj: [{ required: true, message: "请选择开始时间", trigger: "blur" }],
        jssj: [{ required: true, message: "请选择结束时间", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getFuwu();
  },
  methods: {
    clickStartTime() {
      this.startTimeOptions.disabledDate = (time) => {
        if (this.Form.endTime) {
          if (time.getTime() > new Date(this.Form.endTime).getTime()) {
            return true;
          }
          if (time.getTime() < Date.now() - 8.64e7) {
            return true;
          }
        } else {
          if (time.getTime() < Date.now() - 8.64e7) {
            return true;
          }
        }
      };
    },
    clickEndTime() {
      this.endTimeOptions.disabledDate = (time) => {
        if (this.Form.startTime) {
          return (
            time.getTime() > Date.now ||
            time.getTime() < new Date(this.Form.startTime).getTime()
          );
        } else {
          return time.getTime() < Date.now();
        }
      };
    },
    queren() {
      this.dialogVisible = false;
      // alert('确认')
      this.$router.push('/order')
    },
    //提交预约
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addOrder({
            user_name: this.form.name, //客户姓名
            mobile: this.form.phone, //客户姓名
            bb_userid: this.xsid, //学生ID
            service_name: this.form.fwlx, //海师服务名称
            contract_number: this.form.htbh, //海师服务名称
            start_time: this.form.kssj, //开始时间
            end_time: this.form.jssj, //开始时间
            teacher_id: this.$route.query.id, //海师ID
            course_number: this.form.gtcs, //沟通次数
          }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.dialogVisible = true;
            }
          });
        } else {
          return false;
        }
      });
    },
    //合同输入建议
    async querySearch(queryString, cb) {
      // 调用 callback 返回建议列表的数据
      // if (this.hetong.length > 0) {
      //   console.log(1);
      //   cb(this.hetong);
      // } else {
      //   await this.getHetong();
      //   cb(this.hetong);
      // }
      if(this.form.phone==''){
        return
      }
      await this.getHetong();
        cb(this.hetong);
    },
    //合同输入建议选中
    handleSelect(item) {
      console.log('handleSelect',item);

      this.form.htbh = item.name;
      this.syfwcs = item.syfwcs;
    },
    //获取服务项目
    getFuwu() {
      getServices({
        id: this.$route.query.id,
      }).then((res) => {
        this.hsname = res.data.name; //海师名称
        this.hsfw = res.data.hsfw; //海师服务单选
        this.hsfw_arr = res.data.hsfw_arr; //海师服务下拉
        //赋值默认的海师服务类型
        if (res.data.hsfw.length > 0) {
          this.form.fwlx = res.data.hsfw[0].title;
          this.form.fwlxr = res.data.hsfw[0].title;
        } else {
          this.form.fwlx = res.data.hsfw_arr[0].title;
          this.form.fwlxr = res.data.hsfw_arr[0].title;
        }
      });
    },
    //获取合同信息
    async getHetong() {
      await getContract({ mobile: this.form.phone }).then((res) => {
        if (res.data) {
          this.xsid = res.data.id; //学生ID
          this.form.name = res.data.bbhqkghnew.name;
          this.hetong = res.data.bbhqkghnew.bbkh.contract; //合同数组
        }
      });
    },
    //服务类型最终值
    fwlxChange(v, i) {
      //全部置空
      this.form.fwlxr = "";
      this.hsfw_arr.forEach((item, key) => {
        this.form["fwlx" + key] = "";
      });
      //当前赋值
      this.form[i] = v;
      //真海师身份赋值
      this.form.fwlx = v;
    },
  },
};
</script>

<style scoped lang="scss">
.haishiSub {
  width: 1200px;
  height: 693px;
  margin: auto;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  >img{
    width: 400px;
  }
  //预约页面
  .yuyue_box {
    flex: 1;
    .title {
      height: 70px;
      border-bottom: 1px solid #ededed;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      line-height: 70px;
    }
    .yuyue {
      height: 622px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .yuyue_t1 {
        height: 42px;
        padding: 24px;
        border: 1px solid #ededed;
        position: relative;
        span {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          padding: 0 10px;
          white-space: nowrap;
          box-sizing: border-box;
        }
        p {
          font-size: 28px;
          font-weight: 400;
          color: #333333;
          display: inline-block;
          padding: 0 10px;
          background: white;
          position: relative;
          top: 6px;
        }
      }
      .yuyue_t2 {
        margin-top: 40px;
        display: flex;
        /deep/ .el-form {
          width: 100%;
          .zzsx {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            justify-content: center;
            //单选框
            .el-radio-group {
              .el-radio-button {
                &:focus {
                  box-shadow: none;
                }
                .el-radio-button__inner {
                  font-size: 14px;
                  font-weight: 500;
                  color: #505d69;
                  border: none;
                  padding: 6px 0px;
                  margin: 0px 0px;
                  margin-right: 30px;
                  border-radius: 0px;
                  box-sizing: border-box;
                  border-bottom: 2px solid #ffffff;
                }
              }
              .is-active {
                color: #d99d66;
                .el-radio-button__inner {
                  color: #d99d66;
                  background: none;
                  box-shadow: none;
                  border-bottom: 2px solid #d99d66;
                }
              }
            }
            //下拉框
            .el-select {
              margin-right: 30px;
              display: flex;
              .el-input {
                display: flex;
              }
              .el-input__inner {
                width: 100px !important;
                color: #d99d66;
                border: 1px solid #fff;
                padding: 0px;
                background: none;
                box-shadow: none;
                border-radius: 0px;
                display: inline-block;
                border-bottom: 2px solid white;
              }
              .el-select__caret {
                line-height: 28px;
              }
            }
            //统一输入和选择框字体颜色、背景
            .el-input__inner {
              color: #d99d66;
              background: #fafafa;
            }
            .el-input__inner::-webkit-input-placeholder {
              font-size: 14px;
              font-weight: 500;
              color: #505d69 !important;
            }
            .el-input__inner::-moz-input-placeholder {
              font-size: 14px;
              font-weight: 500;
              color: #505d69 !important;
            }
            .el-input__inner::-ms-input-placeholder {
              font-size: 14px;
              font-weight: 500;
              color: #505d69 !important;
            }
            //下拉列表选中
            .el-select {
              .el-select-dropdown {
                .el-select-dropdown__item.selected {
                  color: #d99d66;
                  font-weight: 500;
                }
              }
            }
          }
          > .el-form-item {
            text-align: center;
            width: 350px;
            margin: auto;
            margin-bottom: 20px;
            .el-form-item__content {
              .el-autocomplete {
                width: 100%;
              }
              .el-input__inner {
                background: #fafafa;
                &.el-input__inner:focus {
                  border-color: #d99d66;
                }
              }
            }
          }
          // 服务次数
          .syfucs {
            text-align: center;
            margin: 25px 0;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
          }
          //提交预约
          .tijiao {
            width: 350px;
            height: 50px;
            background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
            border-radius: 4px;
            border: 1px solid white;
            font-size: 16px;
            font-weight: 500;
            color: #935d14;
            position: relative;
            &:hover {
              opacity: 0.8;
            }
            &:active {
              opacity: 0.8;
              top: -1px;
            }
          }
        }
      }
    }
  }
  //预约成功弹窗
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      width: 450px;
      height: 348px;
      background: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .el-dialog__header {
        padding: 25px 0px;
        border: 1px solid #ededed;
        button {
          display: none;
        }
      }
      .el-dialog__body {
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
        padding: 25px 70px;
        text-align: center;
      }
      .el-dialog__footer {
        padding: 0px 50px 25px;
        .el-button {
          width: 100%;
          background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
          border-radius: 4px;
          border: none;
          font-size: 16px;
          font-weight: 500;
          color: #935d14;
        }
      }
    }
  }
}
</style>
